(function ($) {
  'use strict';

  /**
   * Homepage Formatter - v1
   */
  Drupal.behaviors.homepageFormatterV1 = {
    attach: function (context) {
      var $animateElements = $('.homepage-formatter__row', context);

      $animateElements.each(function () {
        var $animateElement = $(this);

        $animateElement.waypoint({
          handler: function (direction) {
            $animateElements.show();
            $animateElement.toggleClass('come-in', direction === 'down');
          },
          offset: '93%'
        });
      });
    }
  };
})(jQuery);
